.featured-collections {
  padding: 105px 0;
}
.featured-collections .featured-collections__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.featured-collections .featured-collections__heading {
  margin-right: 20px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.25;
}
.featured-collections .featured-collections__link-text {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.11;
  letter-spacing: 0.2em;
}
.featured-collections .featured-collections__link-text::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  opacity: 0;
  height: 1px;
  background-color: #2b2b2b;
  transition-timing-function: ease;
  transition-property: opacity;
  transition-duration: 0.3s;
}
.featured-collections .featured-collections__link {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  text-decoration: unset;
  color: #2b2b2b;
}
.featured-collections .featured-collections__link .icon {
  width: 8px;
  height: 16px;
  margin-left: 18px;
}
.featured-collections .featured-collections__link:focus-visible .featured-collections__link-text {
  outline: 1px solid #2b2b2b;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .featured-collections .featured-collections__link:hover .featured-collections__link-text::after {
    opacity: 1;
  }
}
.featured-collections .featured-collections__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.featured-collections .featured-collections__col {
  width: 25%;
  padding: 0 10px;
}
@media (max-width: 1199px) {
  .featured-collections .featured-collections__col {
    width: 50%;
  }
  .featured-collections .featured-collections__col:nth-child(n+3) {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .featured-collections {
    padding: 55px 0;
  }
  .featured-collections .featured-collections__container {
    padding: 0;
  }
  .featured-collections .featured-collections__info {
    margin-bottom: 12px;
    padding: 0 30px;
  }
  .featured-collections .featured-collections__link .icon {
    width: 6px;
    height: 12px;
    margin-left: 6px;
  }
  .featured-collections .featured-collections__link-text {
    font-size: 16px;
  }
  .featured-collections .featured-collections__row {
    margin: 0;
    padding: 0 25px;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .featured-collections .featured-collections__row::-webkit-scrollbar {
    display: none;
  }
  .featured-collections .featured-collections__col {
    flex-shrink: 0;
    width: 60%;
    padding: 0 5px;
  }
  .featured-collections .featured-collections__col:nth-child(n+3) {
    margin-top: 0;
  }
}
@media (max-width: 575px) {
  .featured-collections .featured-collections__info {
    padding: 0 15px;
  }
  .featured-collections .featured-collections__row {
    padding: 0 10px;
  }
}
@media (max-width: 480px) {
  .featured-collections {
    padding-top: 43px;
    padding-bottom: 52px;
  }
  .featured-collections .featured-collections__heading {
    max-width: 135px;
    font-size: 20px;
    line-height: 1.3;
  }
  .featured-collections .featured-collections__link-text {
    font-size: 12px;
    line-height: 1.33;
  }
  .featured-collections .featured-collections__col {
    width: 75%;
  }
}